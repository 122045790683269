<template>
  <v-card outlined>
    <v-card-title
      ><v-icon left>mdi-account-group</v-icon>{{ $tc('user.contact', 2) }}

      <v-spacer></v-spacer>
      <base-new-outlined-button
        :to="{
          name: 'user-invite',
          params: {
            category: category === 'forest_manager' ? 'supplier' : category,
            groupId
          }
        }"
        >{{ $t('actions.add') }}</base-new-outlined-button
      >
    </v-card-title>
    <v-card-text v-if="userGroup.attributes.users">
      <v-row dense>
        <template v-for="(user, i) in userGroup.attributes.users.data">
          <v-col :key="i" cols="12" md="6">
            <v-sheet :key="user.id" flat>
              <v-list class="grey lighten-5">
                <v-list-item>
                  <v-list-item-avatar>
                    <avatar
                      :username="
                        user.attributes.info.name || user.attributes.email
                      "
                    ></avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{
                      user.attributes.info.name ||
                        user.attributes.info.firstName ||
                        '-'
                    }}</v-list-item-subtitle>
                    <v-list-item-title
                      ><v-icon small class="mr-1" color="grey lighten-2"
                        >mdi-email-outline</v-icon
                      >{{ user.attributes.email }}</v-list-item-title
                    >
                    <v-list-item-title
                      ><v-icon small class="mr-1" color="grey lighten-2"
                        >mdi-phone-outline</v-icon
                      >{{
                        user.attributes.phoneNumberString || '-'
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action
                    ><v-btn
                      icon
                      color="error"
                      small
                      :loading="isLoading"
                      @click="removeFromUserGroup(user)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    ></v-list-item-action
                  >
                </v-list-item>
              </v-list>

              <v-card-actions></v-card-actions>
            </v-sheet>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-alert
      v-if="
        userGroup.attributes.users && !userGroup.attributes.users.data.length
      "
      class="mx-2"
    >
      No contacts in this company
    </v-alert>
  </v-card>
</template>

<script>
import Avatar from 'vue-avatar'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    Avatar
  },
  props: {
    category: {
      type: String,
      required: true
    },
    groupId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({ userGroup: 'userGroup/userGroup' })
  },
  methods: {
    ...mapActions({
      editUserOrganization: 'user/editUserOrganization',
      updateUser: 'user/update',
      fetchUserGroup: 'userGroup/fetch'
    }),
    async removeFromUserGroup(user) {
      this.isLoading = true
      let groupIds = [...user.attributes.groupIds]
      const index = groupIds.findIndex(id => id === this.groupId)
      if (index > -1) groupIds.splice(index, 1)
      await this.updateUser({
        id: user.id,
        type: 'user',
        attributes: { groupIds }
      })
      await this.fetchUserGroup(this.groupId)
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
