<template>
  <v-card v-if="userGroup" outlined>
    <v-card-title
      ><v-icon left>mdi-pine-tree</v-icon
      >{{ $t('formText.speciesInformation') }}
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="ch-noborder">
              <th class="text-left body-2">{{ $t('item.cites') }}</th>
              <th class="text-left body-2">{{ $t('item.category') }}</th>
              <th class="text-left body-2">{{ $t('item.type') }}</th>
              <th class="text-left body-2">{{ $t('item.specie') }}</th>
              <th class="text-left body-2">{{ $t('item.ncCode') }}</th>
              <th class="text-left body-2">{{ $t('item.origin') }}</th>
              <!-- <th></th> -->
            </tr>
          </thead>

          <tbody>
            <tr v-if="!speciesInfo.length" class="ch-noborder">
              <td colspan="7">{{ $t('formText.listIsEmpty') }}</td>
            </tr>
            <tr v-for="(item, i) in speciesInfo" :key="i" class="ch-noborder">
              <td class="text-left">
                <v-icon v-if="item.cites" color="error" left
                  >mdi-alert-circle</v-icon
                >
              </td>
              <td>
                {{ item.category }}
              </td>
              <td>
                {{ item.type }}
              </td>
              <td>
                {{ item.specie.value }}
              </td>
              <td>
                {{ item.ncCode }}
              </td>

              <td>
                {{ item.type }}
              </td>
              <td>
                {{ item.origin }}
              </td>
              <td>
                <v-btn
                  icon
                  small
                  class="mx-1"
                  color="amber accent-2"
                  @click="editRow(i)"
                >
                  <v-icon small>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn icon small color="red accent-1" @click="removeRow(i)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions
      ><v-spacer></v-spacer>

      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn light depressed v-bind="attrs" v-on="on"
            ><v-icon left>mdi-plus</v-icon>{{ $t('actions.add') }}
            {{ $t('item.specie') }}</v-btn
          >
        </template>

        <v-card :loading="isLoading">
          <v-card-title> </v-card-title>
          <v-card-text>
            <BaseSelector
              v-model="userSpecie.category"
              :items="categories.map(c => $t(`item.${c}`))"
              :label="$tc('item.category', 1)"
              :item-text="item => $t(`item.${item}`)"
            />
            <BaseSelector
              v-model="userSpecie.type"
              :label="$t('item.type')"
              :items="types"
            />
            <BaseSelector
              v-model="userSpecie.specie"
              :label="$t('item.specie')"
              :items="species"
              item-text="value"
              return-object
              :placeholder="$t('species.Sapele')"
            />

            <BaseCombobox
              v-model="userSpecie.ncCode"
              :label="$t('item.ncCode')"
              :items="ncCodes"
            />

            <BaseAutocomplete
              v-model="userSpecie.origin"
              :label="$t('item.origin')"
              :items="countries"
              :item-text="item => $t(`country.${item.text}`)"
            />
            <v-checkbox
              v-model="userSpecie.cites"
              :label="$t('item.cites')"
            ></v-checkbox>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="grey lighten-1"
              light
              outlined
              @click="onReset"
              >{{ $t('actions.cancel') }}</v-btn
            >
            <v-btn depressed color="primary" @click="onSubmitSpeciesForm">{{
              $t('actions.save')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  SPECIES,
  COUNTRIES,
  TYPES,
  NC_CODES,
  CATEGORIES
} from '@/common/terms.js'
export default {
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      species: SPECIES,
      types: TYPES,
      ncCodes: NC_CODES,
      categories: CATEGORIES,
      userSpecie: this.createSpecieObject(),
      dialog: false,
      isLoading: false,
      editItemIndex: null
    }
  },
  computed: {
    ...mapGetters({
      userGroup: 'userGroup/userGroup',
      certficate: 'fscStore/certificate'
    }),
    speciesInfo() {
      return this.userGroup.attributes.customAttributes.speciesInfo || []
    },
    countries() {
      return this._.orderBy(
        COUNTRIES,
        [c => this.$t(`country.${c.text}`)],
        ['asc']
      )
    }
  },
  methods: {
    ...mapActions({ updateUserGroup: 'userGroup/update' }),
    createSpecieObject() {
      return {
        cites: false,
        specie: { text: null, value: null },
        ncCode: null,
        category: null,
        type: null,
        origin: null
      }
    },
    async onSubmitSpeciesForm() {
      this.isLoading = true
      const userGroup = { ...this.userGroup }
      if (this.editItemIndex)
        userGroup.attributes.customAttributes.speciesInfo[
          this.editItemIndex
        ] = this.userSpecie
      else
        userGroup.attributes.customAttributes.speciesInfo.push(this.userSpecie)
      await this.updateUserGroup(userGroup)
      this.onReset()
      // this.specieInfo = { specie: '', cite: false }
    },
    removeRow(index) {
      const userGroup = { ...this.userGroup }
      userGroup.attributes.customAttributes.speciesInfo.splice(index, 1)
      const answer = window.confirm(this.$t('alerts.delete'))

      if (!answer) return
      this.updateUserGroup(userGroup)
    },
    editRow(index) {
      this.editItemIndex = index
      this.userSpecie = { ...this.speciesInfo[index] }
      this.dialog = true
    },
    onReset() {
      this.userSpecie = this.createSpecieObject()
      this.isLoading = false
      this.dialog = false
      this.editItemIndex = null
    }
  }
}
</script>

<style scoped>
.ch-noborder td,
th {
  border: none !important;
}
</style>
