<template>
  <v-card outlined>
    <v-card-title class="mb-5 text-h6"
      ><v-icon left>mdi-certificate-outline</v-icon
      >{{ $t('formText.certificatesInformation') }}</v-card-title
    >
    <!-- <base-combobox
      v-model="userCertificate.category"
      :label="$t('item.category')"
      :items="categories"
      :item-text="item => $t(`item.${item}`)"
    />

    <base-field-input
      v-model="userCertificate.code"
      :label="$t('common.code')"
    />

    <base-date-picker
      v-model="userCertificate.validDateUntil"
      :label="$t('common.validUntil')"
    /> -->
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="info" depressed @click="onClick"
        ><v-icon left>mdi-plus</v-icon> {{ $t('actions.add') }}
        {{ $t('item.certificate') }}</v-btn
      ></v-card-actions
    > -->
    <v-simple-table v-if="certificates">
      <template v-slot:default>
        <thead>
          <tr class="ch-noborder">
            <th class="text-left body-2">{{ $t('item.category') }}</th>
            <th class="text-left body-2">{{ $t('common.code') }}</th>
            <th class="text-left body-2">{{ $t('common.validUntil') }}</th>
            <th class="text-left body-2">
              {{ $t('blockchain.blockchainValidationHash') }}
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="!certificates.length" class="ch-noborder grey lighten-4">
            <td colspan="7">{{ $t('alerts.noCertificatesAdded') }}</td>
          </tr>
          <tr v-for="(item, i) in certificates" :key="i" class="ch-noborder">
            <td>
              {{ item.category }}
            </td>
            <td>
              {{ item.code }}
            </td>
            <td v-if="item.ValidDateUntil">
              {{ item.validDateUntil | moment('LL') }}
            </td>
            <td v-else></td>

            <td>
              <span
                style="max-width: 250px;"
                class="purple--text text--lighten-1 mb-0 d-inline-block text-truncate"
              >
                {{ item.hash }}</span
              >
            </td>

            <td class="text-right">
              <v-btn icon small color="red accent-1" @click="removeRow(i)"
                ><v-icon small>mdi-close</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn light depressed v-bind="attrs" v-on="on"
            ><v-icon left>mdi-plus</v-icon>{{ $t('actions.add') }}
            {{ $t('item.certificate') }}</v-btn
          >
        </template>

        <v-card :loading="isLoading">
          <v-card-title> </v-card-title>
          <v-card-text>
            <BaseCombobox
              v-model="userCertificate.category"
              :label="$t('item.category')"
              :items="categories"
              :item-text="item => $t(`item.${item}`)"
            />

            <BaseFieldInput
              v-model="userCertificate.code"
              :label="$t('common.code')"
            />

            <BaseDatePicker
              v-model="userCertificate.validDateUntil"
              :label="$t('common.validUntil')"
            />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="grey lighten-1"
              light
              outlined
              @click="onReset"
              >{{ $t('actions.cancel') }}</v-btn
            >
            <v-btn depressed color="primary" @click="onClick">{{
              $t('actions.save')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      categories: [
        'FSC CoC Certificate',
        'Control of Legality',
        'Madera Justa'
      ],
      userCertificate: this.createCertificatesObject()
    }
  },
  computed: {
    ...mapGetters({
      userGroup: 'userGroup/userGroup'
    }),
    certificates() {
      return this.userGroup.attributes.customAttributes.certificates || []
    }
  },
  methods: {
    ...mapActions({ updateUserGroup: 'userGroup/update' }),
    createCertificatesObject() {
      return {
        category: 'FSC CoC Certificate',
        code: '',
        validDateUntil: '',
        hash: this.createHashString(64)
      }
    },
    async onClick() {
      this.isLoading = true
      const userGroup = { ...this.userGroup }
      userGroup.attributes.customAttributes.certificates.push(
        this.userCertificate
      )
      await this.updateUserGroup(userGroup)
      this.onReset()
    },
    removeRow(index) {
      const userGroup = { ...this.userGroup }
      userGroup.attributes.customAttributes.certificates.splice(index, 1)

      const answer = window.confirm(this.$t('alerts.delete'))
      if (!answer) return
      this.updateUserGroup(userGroup)
    },
    onReset() {
      this.userCertificate = this.createCertificatesObject()
      this.dialog = false
      this.isLoading = false
    },
    createHashString(length) {
      let hash = '#'
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        hash += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return hash
    }
  }
}
</script>

<style lang="scss" scoped>
.ch-noborder td,
th {
  border: none !important;
}
</style>
