<template>
  <v-card outlined>
    <v-card-title
      ><v-icon class="mr-2">mdi-cloud-sync-outline</v-icon
      >{{ $t('formText.speciesAndProductsCertified') }} - FSC</v-card-title
    >
    <v-card-text>
      <v-list color="grey lighten-4"
        ><v-list-item
          ><v-list-item-content>
            {{ $t('userGroup.licenseCode') }}</v-list-item-content
          ><v-list-item-content>
            {{
              userGroup.attributes.customAttributes.licenseCode ||
                $t('common.notAvailable')
            }}</v-list-item-content
          ></v-list-item
        >
        <v-list-item
          ><v-list-item-content>
            {{ $t('userGroup.licenseStatus') }}</v-list-item-content
          ><v-list-item-content>
            {{
              certificate.LicenseStatus
                ? $t(`common.${certificate.LicenseStatus.toLowerCase()}`)
                : $t('common.notAvailable')
            }}</v-list-item-content
          ></v-list-item
        >
        <v-list-item
          ><v-list-item-content>
            {{ $t('common.validUntil') }}</v-list-item-content
          ><v-list-item-content>
            {{
              certificate.ExpiryDate || $t('common.notAvailable')
            }}</v-list-item-content
          ></v-list-item
        >
        <v-list-item
          ><v-list-item-content>
            {{ $t('common.standard') }}</v-list-item-content
          ><v-list-item-content>
            {{
              certificate.Standard || $t('common.notAvailable')
            }}</v-list-item-content
          ></v-list-item
        ></v-list
      >
    </v-card-text>
    <v-card-text>
      <v-data-table
        v-if="certificate.Products && certificate.Products.length"
        dense
        show-expand
        :headers="tabHeaders"
        single-expand
        item-key="id"
        :items="
          certificate.Products.map(item => ({
            ...item,
            id: Math.random()
              .toString(36)
              .substr(2, 9)
          }))
        "
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ul class="py-3">
              <li v-for="(specie, i) in item.ProductSpecies" :key="i">
                {{ specie.Species }}
              </li>
            </ul>
          </td>
        </template></v-data-table
      ></v-card-text
    >
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      tabHeaders: [
        {
          text: this.$t('item.type'),
          align: 'start',
          sortable: false,
          value: 'ProductLevel2'
        },
        {
          text: this.$tc('common.category', 1),
          sortable: false,
          align: 'start',
          value: 'MainOutputCategory'
        },
        { text: this.$tc('species.name', 2), value: 'data-table-expand' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      certificate: 'fscStore/certificate',
      userGroup: 'userGroup/userGroup'
    })
  }
}
</script>

<style lang="scss" scoped></style>
