<template>
  <v-row>
    <v-col cols="12">
      <UserGroupDetailsCard :group-id="groupId" :category="category" />
      <!-- <UserGroupShowOrgSettings v-if="groupId === currentUserGroup.id"
    /> -->
    </v-col>

    <v-col cols="12">
      <UserGroupUsersCard :group-id="groupId" :category="category"
    /></v-col>

    <v-col cols="12"
      ><DocumentIndex :resource-id="groupId" resource-type="UserGroup"
    /></v-col>
    <v-col v-if="isSupplier" cols="12"><UserGroupReleatedInfoCard /> </v-col>
    <v-col v-if="isSupplier" cols="12"
      ><CertificatesSelectorTableCard :group-id="groupId"
    /></v-col>

    <v-col v-if="isSupplier" cols="12"
      ><UserGroupProductLicenses v-if="isSupplier" :group-id="groupId" />
    </v-col>

    <v-col v-if="isSupplier" cols="12"
      ><SpeciesSelectorTableCard :group-id="groupId"
    /></v-col>
  </v-row>
</template>

<script>
import UserGroupReleatedInfoCard from './components/UserGroupRelatedInfoCard'
import UserGroupDetailsCard from './components/UserGroupDetailsCard'
import UserGroupUsersCard from './components/UserGroupUsersCard'
import SpeciesSelectorTableCard from './components/SpeciesSelectorTableCard'
import CertificatesSelectorTableCard from './components/CertificatesSelectorTableCard'
import UserGroupProductLicenses from './components/UserGroupProductLicenses'
import DocumentIndex from '@/views/document/components/DocumentIndex'
// import UserGroupShowOrgSettings from './components/UserGroupShowOrgSettings'
import {
  mapActions
  //  mapGetters
} from 'vuex'
export default {
  components: {
    UserGroupDetailsCard,
    UserGroupUsersCard,
    SpeciesSelectorTableCard,
    CertificatesSelectorTableCard,
    UserGroupProductLicenses,
    // UserGroupShowOrgSettings,
    UserGroupReleatedInfoCard,
    DocumentIndex
  },
  props: {
    groupId: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    }
  },

  computed: {
    // ...mapGetters({ currentUserGroup: 'auth/currentUserGroup' }),
    isSupplier() {
      return this.category !== 'customer'
    }
  },
  created() {
    this.fetchData(this.groupId)
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchData(to.params.groupId)
    next()
  },
  methods: {
    ...mapActions({
      fetchUserGroup: 'userGroup/fetch',
      fetchFscCertificate: 'fscStore/fetch',
      setCertficate: 'fscStore/setCertficate'
    }),

    async fetchData(groupId) {
      const userGroup = await this.fetchUserGroup(groupId)
      const { licenseCode } = userGroup.attributes.customAttributes
      if (!licenseCode) return this.setCertficate()
      await this.fetchFscCertificate(licenseCode)
    }
  }
}
</script>
