<template>
  <v-card v-if="userGroup" outlined>
    <v-card-title
      ><v-icon left>mdi-wan</v-icon
      >{{ $t('userGroup.additionalInfo') }}</v-card-title
    >
    <v-card-text>
      <v-row v-for="(prop, i) in additionalInfo" :key="i" align="center" dense>
        <v-col cols="10" md="8">
          <v-row align="center">
            <v-btn icon class="ml-1" @click="removePropObject(i)"
              ><v-icon small color="grey lighten-1">mdi-minus</v-icon></v-btn
            >

            <v-card
              v-if="!showPropertyField('name', i)"
              :key="i + componentKey + 'name'"
              :ripple="false"
              class="mr-1"
              width="200"
              flat
              @click.stop="focusField('name', i)"
              ><v-card-text
                >{{ prop.name || $t('common.name') }}
              </v-card-text></v-card
            >
            <v-text-field
              v-if="showPropertyField('name', i)"
              v-model="prop.name"
              :autofocus="showPropertyField('name', i)"
              class="mr-1"
              dense
              solo
              flat
              hide-details
              background-color="grey lighten-4"
              :placeholder="$t('common.name')"
              @blur="blurField(i)"
            ></v-text-field>

            <v-card
              v-if="!showPropertyField('info', i)"
              :key="i + componentKey + 'info'"
              :ripple="false"
              width="500"
              class="mx-1"
              flat
              @click.stop="focusField('info', i)"
              ><v-card-text
                >{{ prop.info || $t('common.empty') }}
              </v-card-text></v-card
            >
            <v-text-field
              v-if="showPropertyField('info', i)"
              v-model="prop.info"
              :autofocus="showPropertyField('info', i)"
              class="mx-1"
              dense
              solo
              flat
              hide-details
              background-color="grey lighten-4"
              :placeholder="$t('common.empty')"
              @blur="blurField(i)"
            ></v-text-field>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn text small class="text-capitalize" @click="addPropertyObject"
        ><v-icon left>mdi-plus</v-icon>{{ $t('common.property') }}</v-btn
      ></v-card-actions
    >
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      editFieldTracker: [],
      componentKey: 0,
      additionalInfo: []
    }
  },
  computed: {
    ...mapGetters({
      userGroup: 'userGroup/userGroup'
    })
  },
  watch: {
    userGroup: {
      immediate: true,
      handler(userGroup) {
        const { additionalInfo } = userGroup.attributes.customAttributes
        this.additionalInfo = additionalInfo ? [...additionalInfo] : []
        this.editFieldTracker = Array(this.additionalInfo.length).fill(null)
      }
    }
  },
  methods: {
    ...mapActions({ userGroupUpdate: 'userGroup/update' }),
    showPropertyField(name, index) {
      if (!this.additionalInfo.length) return
      return this.editFieldTracker[index] === name
    },
    focusField(name, index) {
      this.editFieldTracker[index] = name
      this.componentKey++
    },
    blurField(index) {
      this.editFieldTracker[index] = null
      this.updateUserGroup()
      this.componentKey++
    },

    addPropertyObject() {
      this.editFieldTracker.push(null)
      const propObj = this.createFreshPropObject()
      this.additionalInfo.push({
        ...propObj
      })
    },
    createFreshPropObject() {
      return { name: null, info: null }
    },
    removePropObject(index) {
      this.editFieldTracker.splice(index, 1)
      this.additionalInfo.splice(index, 1)
      this.updateUserGroup()
    },
    updateUserGroup() {
      const userGroup = { ...this.userGroup }
      userGroup.attributes.customAttributes.additionalInfo = this.additionalInfo
      this.userGroupUpdate(userGroup)
    }
  }
}
</script>

<style lang="scss" scoped></style>
