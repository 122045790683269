<template>
  <v-card flat>
    <v-card-title
      >{{ $t('organization.settings') }} <v-spacer></v-spacer
      ><v-btn
        v-if="file"
        small
        color="primary"
        depressed
        @click="uploadPicture"
        >{{ $t('actions.save') }}</v-btn
      ></v-card-title
    >
    <v-list>
      <v-list-item>
        <v-avatar class="mr-4 mb-8">
          <img :src="logo" alt="logo" />
        </v-avatar>

        <v-list-item-content>
          <v-file-input
            v-model="file"
            filled
            :rules="rules"
            accept="image/png, image/jpeg, image/bmp"
            :placeholder="$t('organization.pickAvatar')"
            prepend-icon="mdi-camera"
            show-size
          ></v-file-input>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      file: null,
      rules: [
        value =>
          !value ||
          value.size < 2000000 ||
          this.$t('organization.avatarMaxSize')
      ]
    }
  },
  computed: {
    ...mapGetters({ loadedLogo: 'organization/logo' }),
    logo() {
      const staticLogo = require('../../../assets/images/guitar.jpg')
      return this.loadedLogo || staticLogo
    }
  },
  methods: {
    async uploadPicture() {
      await this.$store.dispatch('organization/upload', this.file)
    }
  }
}
</script>
