import { render, staticRenderFns } from "./UserGroupUsersCard.vue?vue&type=template&id=645d4846&scoped=true&"
import script from "./UserGroupUsersCard.vue?vue&type=script&lang=js&"
export * from "./UserGroupUsersCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645d4846",
  null
  
)

export default component.exports