var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(_vm._s(_vm.$tc('user.contact', 2))+" "),_c('v-spacer'),_c('base-new-outlined-button',{attrs:{"to":{
        name: 'user-invite',
        params: {
          category: _vm.category === 'forest_manager' ? 'supplier' : _vm.category,
          groupId: _vm.groupId
        }
      }}},[_vm._v(_vm._s(_vm.$t('actions.add')))])],1),(_vm.userGroup.attributes.users)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.userGroup.attributes.users.data),function(user,i){return [_c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{key:user.id,attrs:{"flat":""}},[_c('v-list',{staticClass:"grey lighten-5"},[_c('v-list-item',[_c('v-list-item-avatar',[_c('avatar',{attrs:{"username":user.attributes.info.name || user.attributes.email}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(user.attributes.info.name || user.attributes.info.firstName || '-'))]),_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey lighten-2"}},[_vm._v("mdi-email-outline")]),_vm._v(_vm._s(user.attributes.email))],1),_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey lighten-2"}},[_vm._v("mdi-phone-outline")]),_vm._v(_vm._s(user.attributes.phoneNumberString || '-'))],1)],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"error","small":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.removeFromUserGroup(user)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1),_c('v-card-actions')],1)],1)]})],2)],1):_vm._e(),(
      _vm.userGroup.attributes.users && !_vm.userGroup.attributes.users.data.length
    )?_c('v-alert',{staticClass:"mx-2"},[_vm._v(" No contacts in this company ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }