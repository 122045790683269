<template>
  <v-card v-if="userGroup" outlined>
    <v-card-title class="text-h4">
      <v-btn class="mr-2" icon color="warning" @click="$router.go(-1)"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >{{ name }}
      <v-chip color="amber" class="ml-4">{{
        $tc(`user.${category}`, 1)
      }}</v-chip>
      <v-spacer></v-spacer>
      <!-- <v-btn
        v-if="userGroup.id === 'd8d3edc3-8b30-4f4c-8319-e0e6f44d7333'"
        :to="{ name: 'organization-new' }"
        color="secondary"
        depressed
        class="mr-2"
        ><v-icon left>mdi-plus</v-icon>Organización</v-btn
      > -->
      <v-btn outlined :to="{ path: 'edit' }" append color="primary"
        ><v-icon left>mdi-clipboard-edit-outline</v-icon
        >{{ $t('actions.edit') }}</v-btn
      >
      <!-- <dropdown-button :resource="user" userRole="Vendor"
    /> -->
    </v-card-title>
    <v-divider></v-divider>
    <!-- <v-card-subtitle class="body-1 green lighten-4 pl-8">{{
      $tc(`user.${category}`, 1)
    }}</v-card-subtitle> -->
    <v-row
      ><v-col cols="12" md="6"> <BaseAddressCard :address="address"/></v-col>
      <v-col>
        <UserGroupShowOrgSettings v-if="groupId === currentUserGroup.id"
      /></v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserGroupShowOrgSettings from './UserGroupShowOrgSettings'
export default {
  components: {
    UserGroupShowOrgSettings
  },
  props: {
    category: {
      type: String,
      required: true
    },
    groupId: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      userGroup: 'userGroup/userGroup',
      currentUserGroup: 'auth/currentUserGroup'
    }),
    name() {
      return this.userGroup.attributes.name
    },
    address() {
      return this.userGroup.attributes.customAttributes.address
    }
  },
  async created() {
    // await this.fetchUserGroup(this.groupId)
  },
  methods: {
    ...mapActions({ fetchUserGroup: 'userGroup/fetch' })
  }
}
</script>

<style lang="scss" scoped></style>
